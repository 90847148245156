import Button from '@components/atoms/Button';
import Input from '@components/atoms/Input';
import PhoneInput from '@components/atoms/PhoneInput';
import styled from 'styled-components';

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  position: relative;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 20px;
  row-gap: 30px;
  width: 75%;
  z-index: 10;
  @media (max-width: 1040px) {
    width: 100%;
  }
  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  grid-column: 1 / -1;

  @media (max-width: 560px) {
    align-items: start;
  }
`;

export const CheckboxLabel = styled.label`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.1px;
  color: white;
  width: 64%;
  @media (max-width: 1040px) {
    width: 100%;
  }
  @media (max-width: 560px) {
    font-size: 12px;
  }
`;

export const StyledInput = styled(Input)`
  max-height: 50px;
  width: 100%;
  label {
    text-transform: none;
    top: -7px;
    font-weight: 400;
    letter-spacing: 0.1px;
    color: white;
  }
`;

export const StyledPhoneInput = styled(PhoneInput)`
  max-height: 50px;
  margin-right: 30px;
  label {
    text-transform: none;
    top: -7px;
    font-weight: 400;
    letter-spacing: 0.1px;
    color: white;
  }
`;

export const MessageInput = styled(StyledInput)`
  grid-column: 1 / 2.7;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    margin-bottom: 0;
  }
  @media (max-width: 700px) {
    grid-column: 1;
  }
`;

export const EmailInput = styled(StyledInput)``;

export const StyledButton = styled(Button)`
  font-size: 16px;
  max-height: 50px;
  width: 100%;
  height: 50px;
  padding: 10px 0;
`;

export const ImageWrapper = styled.div`
  display: block;
  position: absolute;
  top: -60%;
  left: 82%;
  width: 427px;
  z-index: -1;

  @media (max-width: 1105px) {
    left: 78%;
  }
  @media (max-width: 1040px) {
    display: none;
  }
`;

export const ThreeFields = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  @media (max-width: 625px) {
    grid-template-columns: 1fr;
  }
`;

export const TwoFields = styled.div<{fullWidth?: boolean}>`
  display: grid;
  width: 100%;
  grid-template-columns: ${({fullWidth}) => (fullWidth ? '1fr' : '65.3% 1fr')};
  gap: 30px;
  @media (max-width: 625px) {
    grid-template-columns: 1fr;
  }
`;
