import {callbackFormValidationSchema} from '@api/schemas/CallBackFormSchema';
import {
  ApplicationFormInitialValues,
  ApplicationFormValues,
} from '@api/types/CallBackFormSchemeType';
import Checkbox from '@components/atoms/Checkbox';
import DownloadFileByCode from '@components/atoms/DownloadFileByCode';
import Captcha from '@components/molecules/Captcha';
import {ENABLE_RAL_CAPTCHA} from '@config/captcha';
import {theme} from '@styles/theme';
import {Formik, FormikProps} from 'formik';
import Image from 'next/legacy/image';
import {RefObject, useRef} from 'react';
import {useGoogleReCaptcha} from 'react-google-recaptcha-v3';
import {
  CheckboxLabel,
  CheckboxWrapper,
  EmailInput,
  Form,
  ImageWrapper,
  MessageInput,
  StyledButton,
  StyledInput,
  StyledPhoneInput,
  ThreeFields,
  TwoFields,
} from './OnlineApplicationBlockForm.styles';

interface OnlineApplicationBlockFormProps {
  onSubmitHandler: (
    values: ApplicationFormValues,
    formikRef: RefObject<FormikProps<ApplicationFormInitialValues>>,
  ) => Promise<void>;
  btnText?: string;
}

const OnlineApplicationBlockForm = ({
  onSubmitHandler,
  btnText = 'Оставить заявку',
}: OnlineApplicationBlockFormProps) => {
  const formikRef = useRef<FormikProps<ApplicationFormInitialValues>>(null);
  const ralCaptchaEnabled = ENABLE_RAL_CAPTCHA;
  const {executeRecaptcha} = useGoogleReCaptcha();

  const initialValue = {
    fio: '',
    phone: '',
    email: '',
    text: '',
    agree: false,
    'g-recaptcha-response': '',
    captcha: '',
  };

  return (
    <Formik
      initialValues={initialValue}
      onSubmit={async values => {
        let token = '';
        if (executeRecaptcha) token = await executeRecaptcha();
        onSubmitHandler({...values, ['g-recaptcha-response']: token}, formikRef);
      }}
      validationSchema={callbackFormValidationSchema}
      innerRef={formikRef}>
      {({handleSubmit, handleChange, values, setFieldValue, errors, touched}) => (
        <Form onSubmit={handleSubmit}>
          <ThreeFields>
            <StyledInput
              id="fio"
              label="Как к вам обращаться? *"
              type="string"
              value={values.fio}
              labelBGColor={theme.colors.lightGreen}
              borderColor={theme.colors.lightGrayV2}
              labelColor={theme.colors.white}
              textColor="white"
              onChange={handleChange}
              style={{marginRight: 20}}
              error={touched.fio && errors.fio ? errors.fio : ''}
            />
            <StyledPhoneInput
              id="phone"
              label="Контактный телефон *"
              onChange={e => setFieldValue('phone', e)}
              value={values.phone}
              labelBGColor={theme.colors.lightGreen}
              borderColor={theme.colors.lightGrayV2}
              labelColor={theme.colors.white}
              textColor="white"
              error={touched.phone && errors.phone ? errors.phone : ''}
            />
            <EmailInput
              id="email"
              label="Почта *"
              type="string"
              value={values.email}
              labelBGColor={theme.colors.lightGreen}
              borderColor={theme.colors.lightGrayV2}
              labelColor={theme.colors.white}
              textColor="white"
              onChange={handleChange}
              error={touched.email && errors.email ? errors.email : ''}
            />
          </ThreeFields>
          <TwoFields fullWidth={ralCaptchaEnabled}>
            <MessageInput
              id="text"
              label="Сообщение"
              type="string"
              value={values.text}
              labelBGColor={theme.colors.lightGreen}
              borderColor={theme.colors.lightGrayV2}
              labelColor={theme.colors.white}
              textColor="white"
              onChange={handleChange}
              error={touched.text && errors.text ? errors.text : ''}
            />
            {!ralCaptchaEnabled && (
              <StyledButton type="submit" title={btnText} variant="filled" />
            )}
          </TwoFields>
          {ralCaptchaEnabled ? (
            <TwoFields>
              <Captcha
                type={'ral'}
                onChange={handleChange}
                value={values.captcha}
                error={
                  ENABLE_RAL_CAPTCHA
                    ? errors.captcha && touched.captcha
                      ? errors.captcha
                      : ''
                    : errors['g-recaptcha-response'] && touched['g-recaptcha-response']
                    ? errors['g-recaptcha-response']
                    : ''
                }
                validationError={
                  ENABLE_RAL_CAPTCHA
                    ? Boolean(errors.captcha && touched.captcha)
                    : Boolean(
                        errors['g-recaptcha-response'] && touched['g-recaptcha-response'],
                      )
                }
              />
              <StyledButton type="submit" title={btnText} variant="filled" />
            </TwoFields>
          ) : (
            <Captcha type={'recaptcha'} />
          )}
          <CheckboxWrapper>
            <Checkbox
              id="agree"
              checked={values.agree}
              onChange={handleChange}
              checkMarkPosTop={13}
              checkMarkPosRight={20}
              checkboxSquareHeight={24}
              checkboxSquareWidth={24}
              marginBetweenLabel={10}
              squareColor={theme.colors.white}
              error={touched.agree && errors.agree ? errors.agree : ''}
              label=""
            />
            <CheckboxLabel htmlFor="agree">
              Нажимая кнопку «Отправить заявку», вы соглашаетесь с{' '}
              <DownloadFileByCode
                code="personal_information"
                color={theme.colors.brandYellowV2}>
                «Политикой в области обработки и защиты персональных данных»
              </DownloadFileByCode>
              , а также даете{' '}
              <DownloadFileByCode
                code="soglasie_na_obrabotku_personalnykh_dannykh"
                color={theme.colors.brandYellowV2}>
                согласие на обработку персональных данных
              </DownloadFileByCode>
              , указанных вами в заявке.
            </CheckboxLabel>
          </CheckboxWrapper>
          <ImageWrapper>
            <Image
              src={'/assets/bannerImages/AdditionalFormImg.svg'}
              layout="responsive"
              width={100}
              height={100}
              alt="image"
            />
          </ImageWrapper>
        </Form>
      )}
    </Formik>
  );
};

export default OnlineApplicationBlockForm;
