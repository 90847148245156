import {setCallback} from '@api/clientServices';
import {sendToCalltouch} from '@api/telemetry';
import {
  ApplicationFormInitialValues,
  ApplicationFormValues,
} from '@api/types/CallBackFormSchemeType';
import FormSuccessMessage from '@components/molecules/FormSuccessMessage';
import OnlineApplicationBlockForm from '@components/molecules/OnlineApplicationBlockForm';
import {GlobalContainer} from '@styles/pages/_app.styles';
import {displayBackendErrors} from '@utils/forms';
import {FormikProps} from 'formik';
import router from 'next/router';
import {MutableRefObject, Ref, useState} from 'react';
import ym from 'react-yandex-metrika';
import {
  FormContainer,
  FormWrapper,
  OnlineApplicationBlockContainer,
  OnlineApplicationBlockTitle,
  OnlineApplicationBlockWrapper,
} from './OnlineApplicationBlock.styles';

interface OnlineApplicationBlockProps {
  title?: string;
  id_CRMProduct?: string;
  id_SherpProduct?: string;
}

const OnlineApplicationBlock = ({
  title = 'Заявка на обратный звонок',
  id_CRMProduct,
  id_SherpProduct,
}: OnlineApplicationBlockProps) => {
  const [responseSuccess, setResponseSuccess] = useState(false);
  const onSubmitHandler = async (
    values: ApplicationFormValues,
    formikRef: Ref<FormikProps<ApplicationFormInitialValues>>,
  ) => {
    const res = await setCallback({
      ...values,
      src: 'site',
      clientURI: window.location.href,
      id_CRMProduct: id_CRMProduct ?? null,
      id_SherpProduct: id_SherpProduct ?? null,
    });

    setResponseSuccess(res.success);
    if (res.success) {
      const calltouchRes = await sendToCalltouch({
        fio: values.fio,
        phoneNumber: values.phone,
        email: values.email,
        subject: 'Заказать обратный звонок',
        requestNumber: res.id_request,
      });

      if (
        router.asPath.match(/\/catalog\/item\/\d+\//) ||
        router.asPath.match(/\/sale\/\d+\//)
      ) {
        ym('reachGoal', 'oz-s-kartochki-tekhniki');
      } else {
        ym('reachGoal', 'callback_send');
      }

      (
        formikRef as MutableRefObject<FormikProps<ApplicationFormInitialValues>>
      ).current.resetForm();
    } else {
      displayBackendErrors(
        res.errors_list,
        (formikRef as MutableRefObject<FormikProps<ApplicationFormInitialValues>>).current
          .setErrors,
      );
    }
  };

  return (
    <OnlineApplicationBlockWrapper id="applicationBlockForm">
      <GlobalContainer>
        <OnlineApplicationBlockContainer>
          <FormWrapper>
            <FormContainer>
              <OnlineApplicationBlockTitle>{title}</OnlineApplicationBlockTitle>
              <OnlineApplicationBlockForm
                btnText="Отправить сообщение"
                onSubmitHandler={onSubmitHandler}
              />
            </FormContainer>
            {!!responseSuccess && (
              <FormSuccessMessage onButtonClick={() => setResponseSuccess(false)} />
            )}
          </FormWrapper>
        </OnlineApplicationBlockContainer>
      </GlobalContainer>
    </OnlineApplicationBlockWrapper>
  );
};

export default OnlineApplicationBlock;
