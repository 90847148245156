import {russianPhoneRegExp} from '@utils/formatters';
import * as Yup from 'yup';

export const callbackFormValidationSchema = Yup.object().shape({
  fio: Yup.string().min(3, 'Недопустимая длина строки').required('Обязательное поле'),
  phone: Yup.string()
    .matches(russianPhoneRegExp, 'Проверьте правильность ввода номера')
    .required('Обязательное поле'),
  email: Yup.string().email('Невалидное значение').required('Поле обязательно'),
  text: Yup.string(),
  agree: Yup.boolean().oneOf([true], 'Вы должны согласиться с условиями'),
});

export const ProductCardApplicationFormModalSchema = Yup.object().shape({
  organization: Yup.string().required('Обязательное поле'),
  inn: Yup.string().required('Обязательное поле'),
  fio: Yup.string().min(3, 'Недопустимая длина строки').required('Обязательное поле'),
  city: Yup.string().required('Обязательное поле'),
  phone: Yup.string()
    .matches(russianPhoneRegExp, 'Проверьте правильность ввода номера')
    .required('Обязательное поле'),
  email: Yup.string().email('Невалидное значение').required('Поле обязательно'),
  text: Yup.string(),
  agree: Yup.boolean().oneOf([true], 'Вы должны согласиться с условиями'),
});
