import {theme} from '@styles/theme';
import styled from 'styled-components';

export const OnlineApplicationBlockWrapper = styled.div`
  position: relative;
  background: ${({theme}) => theme.colors.lightGreen};
  padding-bottom: 97px;
  z-index: 2;

  @media (max-width: 768px) {
    padding-bottom: 50px;
  }
`;

export const OnlineApplicationBlockContainer = styled.div`
  padding-top: 72px;
  height: 100%;
  @media (max-width: 768px) {
    padding-top: 0;
  }
`;

export const OnlineApplicationBlockTitle = styled.h3`
  font-family: ${theme.fonts.headline};
  font-size: 36px;
  line-height: 43px;
  color: #fff;
  padding-bottom: 50px;
  @media (max-width: 768px) {
    padding-top: 50px;
    padding-bottom: 30px;
    font-size: 24px;
  }
  @media (max-width: 560px) {
    font-size: 20px;
  }
`;

export const FormWrapper = styled.div`
  position: relative;
`;

export const FormContainer = styled.div``;
