import styled from 'styled-components';

interface FormSuccessMessageStyledProps {
  bgColor: 'lightGreen' | 'lightBG' | 'white';
}

export const FormMessageContainer = styled.div<FormSuccessMessageStyledProps>`
  position: absolute;
  top: -5px;
  bottom: -5px;
  left: -25px;
  right: -25px;
  padding: 5px 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 20;
  background: ${({theme, bgColor}) => theme.colors[bgColor]};
  @media (max-width: 730px) {
    left: -10px;
    right: -10px;
  }
`;

export const FormMessage = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 768px) {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    gap: 32px;
  }
`;

export const FormMessageContents = styled.div`
  max-width: 490px;
`;

export const MessageHeading = styled.div<FormSuccessMessageStyledProps>`
  font-family: ${({theme}) => theme.fonts.headline};
  font-size: 34px;
  letter-spacing: -0.25px;
  background: ${({theme, bgColor}) => theme.colors[bgColor]};
  color: ${({theme, bgColor}) =>
    bgColor === 'lightGreen' ? theme.colors.white : theme.colors.darkV2};
  @media (max-width: 768px) {
    font-size: 24px;
  }
  @media (max-width: 560px) {
    font-size: 20px;
  }
`;

export const MessageText = styled.div<FormSuccessMessageStyledProps>`
  font-size: 18px;
  color: ${({theme, bgColor}) =>
    bgColor === 'lightGreen' ? theme.colors.lightBG : theme.colors.gray};
  margin-top: 40px;
  margin-bottom: 65px;
  @media (max-width: 768px) {
    font-size: 16px;
    margin-top: 20px;
    margin-bottom: 30px;
  }
  @media (max-width: 560px) {
    font-size: 15px;
  }
`;

export const ImageWrapper = styled.div`
  position: relative;
  width: 45%;
  height: 300px;
  flex-shrink: 0;
  margin-top: 25px;

  @media (max-width: 768px) {
    width: 100%;
    height: 170px;
    margin-top: 0;
  }
`;
