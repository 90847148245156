import Button from '@components/atoms/Button';
import {useWindowDimensions} from '@hooks';
import Image from 'next/legacy/image';
import {
  FormMessage,
  FormMessageContainer,
  FormMessageContents,
  ImageWrapper,
  MessageHeading,
  MessageText,
} from './FormSuccessMessage.styles';

interface FormSuccessMessageProps {
  bgColor?: 'lightGreen' | 'lightBG' | 'white';
  onButtonClick: () => void;
  title?: string;
  text?: string;
}

const FormSuccessMessage = ({
  bgColor = 'lightGreen',
  onButtonClick,
  title = 'Заявка успешно отправлена!',
  text = 'Мы получили ваш запрос, наш менеджер свяжется с вами в ближайшее время',
}: FormSuccessMessageProps) => {
  const {width} = useWindowDimensions();

  return (
    <FormMessageContainer bgColor={bgColor}>
      <FormMessage>
        <FormMessageContents>
          <MessageHeading bgColor={bgColor}>{title}</MessageHeading>
          <MessageText bgColor={bgColor}>{text}</MessageText>
          <Button
            title="Готово"
            variant="filled"
            fontSize={16}
            style={{
              padding: '15px 100px',
              width: width && width <= 500 ? '100%' : 'auto',
            }}
            onClick={onButtonClick}
          />
        </FormMessageContents>
        <ImageWrapper>
          <Image
            src={
              bgColor === 'lightGreen'
                ? '/assets/bannerImages/SuccessBannerWhite.svg'
                : '/assets/bannerImages/SuccessBanner.svg'
            }
            layout="fill"
            alt="image"
          />
        </ImageWrapper>
      </FormMessage>
    </FormMessageContainer>
  );
};

export default FormSuccessMessage;
